






























































































import { getHighestRole, getHighestRoleLabel } from "@/helpers";
import { Role, User, CoreUserGetters } from "@/spect8-core-vue/src/types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Account, AccountActions, AccountGetters } from "./types";

@Component({
  components: {},
})
export default class AccountsList extends Vue {
  @Getter(AccountGetters.Accounts)
  readonly accounts!: Account[];

  @Getter(CoreUserGetters.User)
  readonly user!: User | null;

  @Action(AccountActions.SetAccounts)
  fetchAccounts!: () => Promise<void>;

  @Action(AccountActions.SetEditAccount)
  editAccount!: (item: Account) => Promise<void>;

  @Action(AccountActions.DeleteAccount)
  deleteAccountAction!: (accountId: string) => Promise<void>;

  confirmDelete = false;
  toBeDeleted: Account | null = null;

  search = "";

  get headers() {
    return [
      {
        text: this.$t("accounts.colEmail"),
        align: "start",
        sortable: false,
        value: "email",
      },
      { text: this.$t("accounts.colRole"), value: "roles" },
      {
        text: this.$t("accounts.colBroadcasts"),
        value: "moderatedBroadcastIds",
      },
      {
        text: this.$t("accounts.colActions"),
        value: "actions",
        sortable: false,
        align: "center",
        cellClass: "text-no-wrap",
      },
    ];
  }

  // Accounts only seem to save the last part of the user's id hence the includes check
  get accountsFiltered(): Account[] {
    return this.accounts.filter((acc) => !this.user?.id.includes(acc.id));
  }

  getHighestRole = getHighestRole;
  getHighestRoleLabel = getHighestRoleLabel;
  Role = Role;

  mounted() {
    this.fetchAccounts();
  }

  handleDelete(p: Account) {
    this.toBeDeleted = p;
    this.confirmDelete = true;
  }

  deleteAccount(p: Account | null) {
    if (!p) return;
    this.confirmDelete = false;
    this.toBeDeleted = null;
    this.deleteAccountAction(p.id).then(() => {
      this.$toast.success(this.$i18n.t("deleted.account"));
    });
  }
}
