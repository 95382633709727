











import PageTemplate from "@/components/PageTemplate.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";
import AccountsList from "@/components/Accounts/AccountsList.vue";
import AccountsForm from "@/components/Accounts/AccountsForm.vue";

@Component({
  components: {
    AccountsList,
    AccountsForm,
    PageTemplate,
  },
})
export default class Accounts extends Vue {
  RouteName = RouteName;
}
