var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"page-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.accountsFiltered,"headers":_vm.headers,"search":_vm.search,"footer-props":{
        'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header-search"},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("accounts.listTitle"))+" ("+_vm._s(_vm.accounts.length)+") ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-magnify","placeholder":_vm.$t('forms.search'),"single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.roles",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHighestRoleLabel(item.roles))+" ")]}},{key:"item.moderatedBroadcastIds",fn:function(ref){
      var item = ref.item;
return [(
            _vm.getHighestRole(item.roles) === _vm.Role.BROADCAST_MODERATOR &&
            item.moderatedBroadcastIds &&
            item.moderatedBroadcastIds.length > 0
          )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"brand","dark":"","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("accounts.colBroadcasts"))+" ")])]}}],null,true)},[_c('v-list',{staticStyle:{"max-height":"300px"}},_vm._l((item.moderatedBroadcastIds),function(broadcast,index){return _c('v-list-item',{key:index,staticStyle:{"background-color":"white"}},[_c('v-list-item-title',[_vm._v(_vm._s(broadcast.broadcastName))])],1)}),1)],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.editAccount(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1),(_vm.toBeDeleted)?_c('v-dialog',{attrs:{"max-width":"390px"},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"h5"},[_vm._v(" "+_vm._s(_vm.$t("dialogDeleteTitle", { item: _vm.toBeDeleted ? ("(" + (_vm.toBeDeleted.email) + ")") : "", }))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.confirmDelete = false}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":function($event){return _vm.deleteAccount(_vm.toBeDeleted)}}},[_vm._v(" "+_vm._s(_vm.$t("Delete"))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }