




















































































import { getHighestRole, getRoleSelectionItems } from "@/helpers";
import { Role } from "@/spect8-core-vue/src/types";
import { VForm } from "@/types";
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import {
  Account,
  AccountActions,
  AccountActionPayload,
  AccountGetters,
} from "./types";
import { ValidationObserver, ValidationProvider } from "vee-validate";

@Component({ components: { ValidationObserver, ValidationProvider } })
export default class AccountsForm extends Vue {
  @Ref("form") form!: VForm;
  @Ref("observer") observer!: InstanceType<typeof ValidationObserver>;

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  @Getter(AccountGetters.EditAccount)
  readonly editAccount!: Account | undefined;

  @Getter(AccountGetters.Accounts)
  readonly accounts!: Account[];

  @Action(AccountActions.CreateAccount)
  addAccount!: (account: AccountActionPayload) => Promise<void>;

  @Action(AccountActions.UpdateAccount)
  updateAccount!: (account: Partial<AccountActionPayload>) => Promise<void>;

  @Action(AccountActions.ClearEditAccount)
  clearEdit!: () => Promise<void>;

  min = 6;
  max = 12;
  roleItems = getRoleSelectionItems([
    Role.ADMIN,
    Role.MODERATOR,
    Role.BROADCAST_MODERATOR,
  ]);

  email = "";
  broadcastIds: string[] = [];
  role: Role | null = null;

  formValid = false;

  get emailRules() {
    let rules: Record<string, unknown> = { email: true };
    if (!this.editAccount)
      rules.emailUnique = { strings: this.accounts.map((acc) => acc.email) };
    return rules;
  }

  @Watch("editAccount")
  onEditChange() {
    this.initialData();
  }

  @Watch("role")
  onRoleChange() {
    if (!this.isBroadcastModerator) {
      this.broadcastIds = [];
    }
  }

  get isBroadcastModerator(): boolean {
    return this.role === Role.BROADCAST_MODERATOR;
  }

  sending = false;
  async submit() {
    if (!this.role) return;

    const valid = await this.observer.validate();
    if (!valid) return;

    this.sending = true;

    const p: AccountActionPayload = {
      id: this.editAccount?.id,
      email: this.email,
      roles: [this.role],
      broadcastIds: this.broadcastIds,
    };

    try {
      if (this.editAccount) {
        await this.updateAccount(p);
        this.$toast.success(this.$i18n.t("updated.account"));
      } else {
        await this.addAccount(p);
        this.$toast.success(this.$i18n.t("created.account"));
      }
      this.discard();
    } catch (error) {
      console.error(error);
    }
  }

  initialData() {
    this.form.resetValidation();
    this.form.reset();

    if (this.editAccount) {
      this.email = this.editAccount.email;
      this.role = getHighestRole(this.editAccount.roles);
      this.broadcastIds = this.editAccount.moderatedBroadcastIds
        ? this.editAccount.moderatedBroadcastIds.map(
            (broadcast) => broadcast.broadcastId
          )
        : [];
    } else {
      this.email = "";
      this.role = null;
      this.broadcastIds = [];
    }
  }

  async discard() {
    if (this.editAccount) {
      await this.clearEdit();
    }
    this.initialData();
  }

  beforeDestroy() {
    this.discard();
  }
}
